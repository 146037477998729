import React from 'react';
import {formatDate, getArticleLinks} from "../utils/articleUtil";

const ArticlePreview = props => {
    const articleLinks = getArticleLinks(props.data);
    const category = articleLinks.category;
    const categoryPath = articleLinks.categoryPath;
    const getReadMore = articleLinks.getReadMore;

    const imageUrl = () => {
        let url = `${props.data.cover.url}?`;
        let qualityGiven = false;
        if (props.imageMeta) {
            const keys = Object.keys(props.imageMeta);
            keys.forEach((key) => {
                url += `${key}=${props.imageMeta[key]}&`;
                !qualityGiven && (qualityGiven = key === 'q')
            });
        }
        if (!qualityGiven) {
            url += 'q=50';
        }
        return url;
    }

    const imageComponent = () => {
        return <figure className="teaser-card__figure">
            <a href={getReadMore()}>
                {
                    props.imageFromUrl
                        ?
                        <img className="teaser-card__image" src={imageUrl()}/>
                        :
                        <amp-img src={props.data.cover.responsiveImage.src} width={props.data.cover.responsiveImage.width} height={props.data.cover.responsiveImage.height} layout="responsive" />
                }
            </a>
        </figure>
    }

    return (
        <div className={`placeholder--teaser-card ${props.customClass ? props.customClass : ''}`} key={props.data.slug}
             style={props.style}>
            <div className="teaser-card">
                {!props.noImage && props.data.cover?.url && !props.wrappedImg && imageComponent()}
                <div className="teaser-card__body">
                    {!props.noImage && props.data.cover?.url && props.wrappedImg && imageComponent()}
                    {!props.noFlag && <a className="teaser-card__flag" href={categoryPath()}>{category.title}</a>}
                    <a className="teaser-card__title" href={getReadMore()}>{props.data.heading}</a>
                    {!props.noBody && <>
                        <p className="teaser-card__text">{props.data.excerpt}</p>
                        <a className="read-more" href={getReadMore()}>Read More</a>
                    </>}
                    {!props.noAuthor && <>
                    <span className="byline">By <a
                        href={`/authors/${props.data.author.slug}`}>{props.data.author.name}</a>
                        {!props.noPublishedDate && <time className={'date'}>{formatDate(props.data.publishedAt, 'mmmm dd, yyyy')}</time>}
                    </span>
                    </>}
                </div>
            </div>
        </div>
    )
}
export default ArticlePreview;
