import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import CategoryTitle from "../helpers/categoryTitle";
import ArticlePreview from "../../templates/articlePreview.amp";
import {isMobile} from "react-device-detect";
import {adDisplayDelay} from "../../utils/articleUtil";
import consts from "../../constants/consts";

class PopularPosts extends React.Component {

    constructor(props) {
        super(props);

        this.adRefreshInterval = null;
    }

    componentDidMount() {
        setTimeout(() => {
            const self = this;
            try {
                window.googletag.cmd.push(function () {
                    const slot3 = googletag.defineSlot('/22218985137/pensiontimes/pt_rightcol_desktop', [300, 250], 'div-pt_rightcol_desktop');
                    if (slot3) {
                        slot3.addService(googletag.pubads());
                        googletag.pubads().enableSingleRequest();
                        googletag.pubads().collapseEmptyDivs();
                        googletag.pubads().setCentering(true);
                        googletag.enableServices();
                        self.adRefreshInterval = setInterval(function () {
                            googletag.pubads().refresh([slot3]);
                        }, consts.AD_REFRESH_TIME);
                    }
                });
            } catch (e) {
                console.log(e);
            }
            try {
                googletag.cmd.push(function () {
                    googletag.display('div-pt_rightcol_desktop');
                });
            } catch (e) {
                console.log(e);
            }
        }, adDisplayDelay() * (isMobile ? 2 : 1));
    }

    componentWillUnmount() {
        this.adRefreshInterval && clearInterval(this.adRefreshInterval);
    }

    renderItems() {
        return <StaticQuery
            query={graphql`
            query PopularPostsQueryAMP {
              datoCMS {
                  allArticles(filter: {cover: {exists: "true"}}, orderBy: publishedAt_DESC, skip: "40", first: "5") {
                    ...ArticleFields
                  }
                }
            }
        `}
            render={data =>
                data.datoCMS.allArticles.map((article, index) => {
                    return <div key={`pp-${article.slug}`}>
                        <div className="popular-post-container-link">
                            <ArticlePreview key={article.slug}
                                            customClass="bottom-border"
                                            data={article}
                                            noAuthor={true}
                                            noImage={index !== 0}
                                            imageMeta={{width: 768}}
                                            noBody={true}/>
                        </div>
                        {
                            {
                                1: <div className="ad-container">
                                    <div className="advertisement-text">Advertisement</div>
                                    <div id='div-pt_rightcol_desktop'></div>
                                </div>
                            }[index]
                        }
                    </div>
                })
            }
        />
    }

    render() {
        return !isMobile && <div className="popular-posts">
            <CategoryTitle title="Popular Posts"/>
            {this.renderItems()}
        </div>
    }
}

export default PopularPosts;
